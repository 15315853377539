import * as React from 'react';
import { SVGProps } from 'react';

export const TreeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m8 2 2.666 2.667-1.333.666L12 8l-2 .667 2.666 2.666H3.333L6 8.667 4 8l2.666-2.667-1.333-.666L8 2ZM9.334 11.334v2a.667.667 0 0 1-.667.666H7.334a.667.667 0 0 1-.667-.666v-2"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
