import * as React from 'react';
import { SVGAttributes } from 'react';

export const BoneIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10 2a2 2 0 0 1 2 2 2 2 0 1 1-1.413 3.415l-3.172 3.172a2 2 0 1 1-3.411 1.531L4 12l-.117-.003a2 2 0 1 1 1.532-3.41l3.172-3.172A2 2 0 0 1 10 2v0Z"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
