import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { darken, lighten } from 'polished';

const Root = styled.a`
	background: ${ThemeColors.brown1};
	border-radius: 10px;
	color: ${ThemeColors.brown3};
	font-size: 16px;
	height: 48px;
	text-align: center;
	width: 100%;
	display: block;
	line-height: 48px;
	font-weight: 500;
	transition: 0.2s;

	:hover {
		background: ${lighten(0.02, ThemeColors.brown3)};
		color: ${darken(0.02, ThemeColors.brown1)};
	}
	:active {
		background: ${darken(0.02, ThemeColors.brown1)};
		box-shadow: inset 2px 2px 10px -8px rgba(0, 0, 0, 0.35);
	}
`;

export const GoogleMapsButton = (
	props: Omit<ComponentPropsWithoutRef<'a'>, 'children'>,
) => (
	<Root target="_blank" {...props}>
		Otwórz w Google Maps
	</Root>
);
