import useSWR from 'swr';
import { LatLngLiteral } from 'leaflet';

import { frontendApi } from './frontend-api';

const fetcher = (_key: string, place: string) =>
	frontendApi.getCoordsFromPlace(place);

export const useGetCoordsFromPlace = (place: string | null) => {
	const { data, error } = useSWR<LatLngLiteral>(
		place ? ['__swr', place] : null,
		fetcher,
	);

	return {
		coords: data,
		error,
	};
};
