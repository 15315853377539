import styled, { css } from 'styled-components';
import { ComponentPropsWithRef } from 'react';
import { ThemeColors } from '@wyjehau/theme';

import { ButtonBase } from '../button-base/button-base';

type AccentProps = {
	accentColor: string;
	active: boolean;
};

const Button = styled(ButtonBase)<AccentProps>`
	${({ accentColor, active }) => {
		const defaultStyle = css`
			background: ${ThemeColors.white};
			color: ${ThemeColors.black};
		`;

		const activeStyle = css`
			background: ${accentColor};
			color: ${ThemeColors.white};
		`;

		return css`
			padding: 0 16px;
			height: 32px;
			box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
			font-size: 13px;
			border-radius: 5px;
			white-space: nowrap;
			transition: 0.2s;
			${active ? activeStyle : defaultStyle}

			&:hover {
				${activeStyle}
			}
		`;
	}}
`;

export const SubcategoryButton = (
	props: ComponentPropsWithRef<'button'> & AccentProps,
) => {
	return <Button {...props} />;
};
