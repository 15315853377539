import styled, { css } from 'styled-components';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { FullLogo } from '../full-logo/full-logo';

type Props = {
	categoryBar: ReactNode;
	placeDetails: ReactNode;
	newPlaceButton: ReactNode;
	zoomControls: ReactNode;
	locateButton: ReactNode;
	subCategories: ReactNode;
	mapTypeSwitcher: ReactNode;
	isPlaceDetailsVisible: boolean;
} & ComponentPropsWithoutRef<'div'>;

const MobileGrid = styled.div<{
	withPlaceDetails: boolean;
	withSubcategories: boolean;
}>`
	padding: 12px 12px 0px 12px;
	display: grid;
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	width: 100%;
	z-index: 999;
	pointer-events: all;
	pointer-events: none;

	${({ withPlaceDetails, withSubcategories }) => {
		const placeDetailsRows = css`
			grid-template-rows: 68px 96px auto fit-content(0px);
		`;

		const baseRows = css`
			grid-template-rows: 68px 96px 0 auto;
		`;

		const withSubcategoriesRows = css`
			grid-template-rows: 68px 96px 64px auto;
		`;

		const subcategoriesAndPlaceDetailsRows = css`
			grid-template-rows: 68px 96px 64px auto fit-content(0px);
		`;

		return css`
			${baseRows};

			${withSubcategories && withSubcategoriesRows};
			${withPlaceDetails && placeDetailsRows};

			${withPlaceDetails &&
			withSubcategories &&
			subcategoriesAndPlaceDetailsRows};

			grid-template-areas:
				'logo'
				'categoryBar'
				'subCategories'
				'controls';
		`;
	}}

	@media (display-mode: fullscreen) {
		padding: 48px 12px 12px 12px;
		min-height: 100vh;
	}
`;

const PositionedLogo = styled(FullLogo)`
	grid-area: logo;
	pointer-events: all;
	align-self: center;
	justify-self: center;
`;

const CategoryWrapper = styled.div`
	grid-area: categoryBar;
	pointer-events: all;
`;

const MobileControlsWrapper = styled.div`
	grid-area: controls;
	pointer-events: none;
	display: grid;
	grid-template-columns: 184px auto 36px;
	grid-template-rows: auto auto auto auto;
	grid-template-areas:
		'. . zoomControls'
		'. . locateButton'
		'newPlace newPlace newPlace';
	align-items: end;
	align-self: end;
	grid-gap: 20px;
`;

const NewPlaceWrapper = styled.div`
	grid-area: newPlace;
	pointer-events: all;
`;

const LocateButtonWrapper = styled.div`
	grid-area: locateButton;
	pointer-events: all;
`;
const ZoomControlsButton = styled.div`
	grid-area: zoomControls;
	pointer-events: all;
`;

const MobilePlaceDetailsWrapper = styled.div`
	margin-top: 20px;
	margin-left: -12px;
	margin-right: -12px;
	pointer-events: all;
`;

const SubcategoriesBar = styled.div`
	grid-area: subCategories;
	margin-top: 16px;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	max-width: 100%;
	scroll-snap-type: x mandatory;
	touch-action: auto;
	pointer-events: all;
`;

export const MobileMapPageGrid = ({
	categoryBar,
	placeDetails,
	subCategories,
	newPlaceButton,
	zoomControls,
	locateButton,
	isPlaceDetailsVisible,
}: Props) => {
	return (
		<MobileGrid
			withSubcategories={Boolean(subCategories)}
			withPlaceDetails={isPlaceDetailsVisible}
		>
			<PositionedLogo variant="mobile" />
			<CategoryWrapper>{categoryBar}</CategoryWrapper>
			<SubcategoriesBar>{subCategories}</SubcategoriesBar>
			{isPlaceDetailsVisible && (
				<MobilePlaceDetailsWrapper>{placeDetails}</MobilePlaceDetailsWrapper>
			)}

			<MobileControlsWrapper>
				{!isPlaceDetailsVisible && (
					<NewPlaceWrapper>{newPlaceButton}</NewPlaceWrapper>
				)}
				<LocateButtonWrapper>{locateButton}</LocateButtonWrapper>
				<ZoomControlsButton>{zoomControls}</ZoomControlsButton>
			</MobileControlsWrapper>
		</MobileGrid>
	);
};
