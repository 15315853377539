import styled, { css } from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';

const shadow = css`
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;

const Root = styled.div(
	{
		position: 'relative',
		height: 48,
		backgroundColor: ThemeColors.white,
		backdropFilter: `blur(10px)`,
		borderRadius: 10,
		display: 'grid',
		gridGap: 8,
		gridTemplateColumns: 'auto fit-content(0px)',
		alignItems: 'center',
		overflow: 'hidden',
		paddingRight: 20,
	},
	shadow,
);

const MobileRoot = styled.div(
	{
		height: 97,
		borderRadius: 10,
		overflow: 'hidden',
	},
	shadow,
);

const MobileTopColumn = styled.div({
	background: ThemeColors.gray1,
	borderBottom: `1px solid ${ThemeColors.gray2}`,
	padding: '0 16px',
});

const MobileBottomColumn = styled.div({
	background: ThemeColors.white,
	padding: '0 16px',
});

const LeftColumn = styled.div({
	background: ThemeColors.gray1,
	height: '100%',
	paddingLeft: 20,
	borderRight: `1px solid ${ThemeColors.gray2}`,
});

const InputFormWrapper = styled.form`
	width: 100%;
`;

export const CategoryBarStyles = {
	Root,
	LeftColumn,
	InputFormWrapper,
	MobileRoot,
	MobileTopColumn,
	MobileBottomColumn,
};
