import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { CategoryType } from '@wyjehau/model';

import { PlaceCategoryDisplay } from '../place-category-display/place-category-display';
import { Sanity } from '../../services/sanity';
import { CloseButton } from '../close-button/close-button';
import { PlaceUnderVerification } from '../place-under-verification/place-under-verification';
import { GoogleMapsButton } from '../google-maps-button/google-maps-button';
import { useAppNavigation } from '../../routing/use-app-navigation';
import { PlaceAddressFormatter } from '../../domain/place-address';

const Headline = styled('h1')({
	fontWeight: 500,
	marginTop: 30,
});

const Address = styled('span')({
	color: ThemeColors.gray3,
	marginBottom: 10,
	display: 'block',
});

const Wrapper = styled.div`
	padding: 16px;
	background: ${ThemeColors.white};
	border-radius: 10px;
	backdrop-filter: blur(10px);
	border: 1px solid #dbd9de;
	height: 100%;
	box-shadow: 0.7px 0.6px 2.2px rgba(0, 0, 0, 0.015),
		1.8px 1.5px 5.3px rgba(0, 0, 0, 0.019),
		3.4px 2.9px 10px rgba(0, 0, 0, 0.022), 6px 5.1px 17.9px rgba(0, 0, 0, 0.026),
		11.3px 9.6px 33.4px rgba(0, 0, 0, 0.035), 27px 23px 80px rgba(0, 0, 0, 0.07);
`;

const StyledCloseButton = styled(CloseButton)`
	float: right;
`;

const PositionedPlaceUnderVerification = styled(PlaceUnderVerification)`
	margin-top: 40px;
`;

const GoogleMapsLink = styled(GoogleMapsButton)`
	margin-top: 50px;
`;

const addressFormatter = new PlaceAddressFormatter();

export const PlaceDetailsDesktop = ({
	place,
	googleLink,
}: {
	place: Sanity.PlaceDto;
	googleLink: string;
}) => {
	const { closeActivePlace } = useAppNavigation();

	return (
		<Wrapper>
			<StyledCloseButton onClick={closeActivePlace} />
			<Headline>{place.title}</Headline>
			<Address>{addressFormatter.prettyPrintAddress(place.address)}</Address>
			<PlaceCategoryDisplay
				category={place.category as CategoryType}
				subcategories={place.subcategories?.items ?? []}
			/>
			{place.published === false && <PositionedPlaceUnderVerification />}
			<GoogleMapsLink href={googleLink} />
		</Wrapper>
	);
};
