import styled from 'styled-components';

import { MapTypes } from '../../map-type';

const BgGradient = styled.div`
	z-index: 500;
	width: 100%;
	height: 160px;
	position: absolute;
	background: linear-gradient(
		to bottom,
		#c2c2d6 0%,
		rgba(255, 255, 255, 0) 100%
	);
	top: 0;
	left: 0;
	pointer-events: none;
`;

const BgSolid = styled.div`
	z-index: 500;
	width: 100%;
	height: 160px;
	position: absolute;
	background: linear-gradient(to bottom, #fff7 0%, rgba(255, 255, 255, 0) 100%);
	-webkit-mask: linear-gradient(
		to bottom,
		#fff 0%,
		rgba(255, 255, 255, 0.7) 100%
	);
	backdrop-filter: grayscale(60%) blur(3px);
	top: 0;
	left: 0;
	pointer-events: none;
`;

export const BackgroundGradient = ({ mapType }: { mapType: MapTypes }) => {
	switch (mapType) {
		case 'map':
			return <BgGradient />;
		case 'satellite':
			return <BgSolid />;
	}
};
