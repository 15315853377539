import { ComponentPropsWithoutRef, lazy, ReactNode, Suspense } from 'react';
import styled from 'styled-components';

import { FullLogo } from '../full-logo/full-logo';
import { ClientOnly } from '../../utils/client-only';
import { ConnectedMenuButton } from '../main-menu/menu-button/menu-button';

const LazyWelcomeModal = lazy(() => import('../welcome-modal/welcome-modal'));

type Props = {
	categoryBar: ReactNode;
	placeDetails: ReactNode;
	newPlaceButton: ReactNode;
	zoomControls: ReactNode;
	locateButton: ReactNode;
	subCategories: ReactNode;
	mapTypeSwitcher: ReactNode;
} & ComponentPropsWithoutRef<'div'>;

const GridRoot = styled.div`
	display: grid;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 999;
	pointer-events: none;
	padding: 32px;
	grid-gap: 24px;
	grid-template-rows: 48px auto;
`;

const TopRow = styled.div`
	display: grid;
	grid-template-columns: 130px auto 48px;
	grid-gap: 30px;
	position: relative;
	pointer-events: all;
`;

const BottomRow = styled.div<{ renderPlaceDetails: boolean }>`
	display: grid;
	grid-gap: 24px;
	grid-template-columns: ${({ renderPlaceDetails }) => {
		return renderPlaceDetails ? 'auto 320px' : '100%';
	}};
`;

const LeftBottomGrid = styled.div<{ subcategoriesVisible: boolean }>`
	display: grid;
	height: 100%;
	grid-template-columns: 130px auto;
	grid-gap: 30px;
	grid-template-rows: ${({ subcategoriesVisible }) => {
		return subcategoriesVisible ? '30px auto' : 'auto';
	}}; ;
`;

const PositionedLogo = styled(FullLogo)`
	grid-column: 1/2;
	pointer-events: all;
`;

const CategoryWrapper = styled.div`
	grid-column: 2/3;
	pointer-events: all;
`;

const SubcategoriesBar = styled.div`
	grid-column: 2/3;
	grid-row: 1/2;
`;

const NewPlaceWrapper = styled.div`
	pointer-events: all;
`;

const LocateButtonWrapper = styled.div`
	pointer-events: all;
	margin-top: 24px;
`;

const ZoomControlsButton = styled.div`
	pointer-events: all;
`;

const PlaceDetailsWrapper = styled.div`
	pointer-events: all;
`;

const BottomControlsGrid = styled.div`
	display: grid;
	grid-template-columns: 200px 244px;
	grid-column: 1/3;
	justify-content: space-between;
	align-items: end;
`;

const welcomeModalStyles = {
	position: 'absolute',
	top: 'calc(100% + 48px)',
} as const;

const MapTypeSwitcherContainer = styled.div`
	pointer-events: all;
`;

const MapControlsGrid = styled.div({
	display: 'grid',
	gridTemplateColumns: '184px 36px',
	gridGap: 24,
	alignItems: 'end',
});

export const DesktopMapPageGrid = ({
	categoryBar,
	placeDetails,
	subCategories,
	newPlaceButton,
	zoomControls,
	locateButton,
	mapTypeSwitcher,
}: Props) => {
	return (
		<GridRoot>
			<TopRow>
				<PositionedLogo variant="desktop" />
				<CategoryWrapper>{categoryBar}</CategoryWrapper>
				<ConnectedMenuButton />
				<ClientOnly>
					<Suspense fallback={null}>
						<LazyWelcomeModal style={welcomeModalStyles} />
					</Suspense>
				</ClientOnly>
			</TopRow>
			<BottomRow renderPlaceDetails={Boolean(placeDetails)}>
				<LeftBottomGrid subcategoriesVisible={Boolean(subCategories)}>
					{subCategories && (
						<SubcategoriesBar>{subCategories}</SubcategoriesBar>
					)}
					<BottomControlsGrid>
						<NewPlaceWrapper>{newPlaceButton}</NewPlaceWrapper>
						<MapControlsGrid>
							<MapTypeSwitcherContainer>
								{mapTypeSwitcher}
							</MapTypeSwitcherContainer>
							<div>
								<ZoomControlsButton>{zoomControls}</ZoomControlsButton>
								<LocateButtonWrapper>{locateButton}</LocateButtonWrapper>
							</div>
						</MapControlsGrid>
					</BottomControlsGrid>
				</LeftBottomGrid>
				{placeDetails && (
					<PlaceDetailsWrapper>{placeDetails}</PlaceDetailsWrapper>
				)}
			</BottomRow>
		</GridRoot>
	);
};
