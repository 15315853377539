import { Map } from 'leaflet';
import * as React from 'react';
import { PropsWithChildren, useContext, useMemo, useState } from 'react';

type ContextType = {
	map: Map | null;
	setMap(map: Map): void;
};

const context = React.createContext<ContextType>({
	map: null,
	setMap(map: Map) {
		throw new Error('Not defined');
	},
});

export const useLeafletMap = () => useContext(context).map;
export const useSetLeafletMap = () => useContext(context).setMap;

export const LeafletMapContext = (props: PropsWithChildren<{}>) => {
	const [map, setMap] = useState<Map | null>(null);

	const value = useMemo((): ContextType => {
		return {
			map: map,
			setMap: setMap,
		};
	}, [map, setMap]);

	return <context.Provider value={value}>{props.children}</context.Provider>;
};
