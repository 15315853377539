import styled from 'styled-components';

const LoaderContainer = styled.div({
	position: 'fixed',
	background: `rgba(255, 255, 255, .3)`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backdropFilter: 'blur(5px)',
	width: '100vw',
	height: '100vh',
	left: 0,
	top: 0,
	zIndex: 999,
});

export const PlacesLoader = () => {
	return (
		<LoaderContainer>
			<div className="lds-heart">
				<div />
			</div>
		</LoaderContainer>
	);
};
