import styled from 'styled-components';
import { ComponentPropsWithoutRef } from 'react';

const Root = styled.div({
	display: 'block',
	borderRadius: 10,
	border: `1px solid #E18D70`,
	padding: 10,
	background: 'rgba(225,141,112,0.16)',
});

const MainMessage = styled.span({
	color: '#E18D70',
	fontWeight: 'bold',
	display: 'block',
	marginBottom: 10,
});

const Explainer = styled.span({});

export const PlaceUnderVerification = (
	props: ComponentPropsWithoutRef<'div'>,
) => {
	return (
		<Root {...props}>
			<MainMessage>To miejsce jest weryfikowane</MainMessage>
			<Explainer>
				Po zatwierdzeniu pokaże się na mapie - ale Twój link działa!
			</Explainer>
		</Root>
	);
};
