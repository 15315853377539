import { categoriesList, CategoryType } from '@wyjehau/model';
import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { ComponentPropsWithoutRef } from 'react';

import { ArrowDown } from '../../icons/assets/arrow-down';

const MobileSelect = styled.select`
	border: none;
	outline: none;
	height: 48px;
	background: transparent;
	color: ${ThemeColors.black};
	font-size: 16px;
	appearance: none;
	width: 100%;
`;

const MobileSelectArrow = styled(ArrowDown)`
	position: absolute;
	right: 16px;
	width: 16px;
	height: 16px;
	top: 50%;
	pointer-events: none;
	transform: translateY(-50%);
`;

const Wrapper = styled.div({
	position: 'relative',
});

type Props = Omit<ComponentPropsWithoutRef<'select'>, 'onChange'> & {
	activeCategory?: CategoryType | null;
	onChange(category: CategoryType | null): void;
};

export const MobileCategoriesSelect = ({
	activeCategory,
	onChange,
	className,
	...props
}: Props) => {
	return (
		<Wrapper className={className}>
			<MobileSelect
				{...props}
				defaultValue={activeCategory ?? 'all'}
				onChange={(e) => {
					if (e.target.value === 'all') {
						onChange(null);
					}

					onChange(e.target.value as CategoryType);
				}}
			>
				<option value="all">Wszystkie</option>
				{categoriesList.map((cat) => (
					<option value={cat.type} key={cat.type}>
						{cat.label}
					</option>
				))}
			</MobileSelect>
			<MobileSelectArrow />
		</Wrapper>
	);
};
