import * as React from 'react';
import { SVGProps } from 'react';

export const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.333 8H2l6-6 6 6h-1.333M3.333 8v4.667A1.333 1.333 0 0 0 4.666 14h6.667a1.333 1.333 0 0 0 1.333-1.333V8"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6 14v-4a1.333 1.333 0 0 1 1.333-1.334h1.334A1.333 1.333 0 0 1 10 10v4"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
