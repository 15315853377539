import styled from 'styled-components';
import { HTMLAttributes, useMemo } from 'react';
import { CategoryType, getCategoryByName } from '@wyjehau/model';

import { Icon } from '../../icons/icons';

type Props = {
	category: CategoryType;
	subcategories: string[];
} & HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div<{ bg: string }>`
	height: 30px;
	display: inline-flex;
	background: ${({ bg }) => bg};
	color: white;
	align-items: center;
	padding: 0 14px;
	border-radius: 10px;
	overflow: hidden;

	& > span {
		font-size: 13px;
		margin-left: 5px;
	}
`;

export const PlaceCategoryDisplay = ({
	category,
	subcategories,
	...props
}: Props) => {
	const categoryModel = getCategoryByName(category);
	const IconToDisplay = Icon[categoryModel.icon];

	const label = useMemo(() => {
		const subcategory = subcategories && subcategories[0] && subcategories[0];

		if (subcategory) {
			return categoryModel.subcategories.find(
				(subCat) => subCat.type === subcategory,
			)?.label;
		}

		return categoryModel.label;
	}, [
		category,
		subcategories,
		categoryModel.label,
		categoryModel.subcategories,
	]);

	return (
		<Wrapper bg={categoryModel.color} {...props}>
			{Icon ? <IconToDisplay width={16} /> : null}
			<span>{label}</span>
		</Wrapper>
	);
};
