import styled, { css } from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { ComponentPropsWithoutRef } from 'react';

import { MapType, MapTypes } from '../../map-type';

const Wrapper = styled.div`
	background: ${ThemeColors.gray1};
	border-radius: 12px;
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const Button = styled.button<{ active: boolean }>`
	height: 36px;
	color: ${ThemeColors.gray3};
	text-align: center;
	border-radius: 12px;

	${({ active }) => {
		if (active) {
			return css`
				background: ${ThemeColors.white};
				color: ${ThemeColors.black};
				box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
			`;
		}
	}}
`;

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> & {
	active: MapTypes;
	onChange(newVal: MapTypes): void;
};

export const MapTypeSwitcher = ({ active, onChange, ...props }: Props) => {
	const handleButtonClick = () => {
		onChange(MapType.toggleType(active));
	};

	return (
		<Wrapper {...props}>
			<Button onClick={handleButtonClick} active={active === 'map'}>
				Mapa
			</Button>
			<Button onClick={handleButtonClick} active={active === 'satellite'}>
				Satelita
			</Button>
		</Wrapper>
	);
};
