export class FrontendApi {
	getCoordsFromPlace(place: string): Promise<{ lat: number; lng: number }> {
		return fetch(`/api/geolocate/place?q=${place}`).then((r) => r.json());
	}

	fetchPlaces(category?: string, subcategory?: string) {
		const queryParams = new URLSearchParams();

		if (category) {
			queryParams.append('category', category);
		}

		if (subcategory) {
			queryParams.append('subcategory', subcategory);
		}

		return fetch(`/api/places?${queryParams.toString()}`).then((r) => r.json());
	}
}

export const frontendApi = new FrontendApi();
