import { lazy, Suspense, useMemo, useRef } from 'react';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { Categories } from '@wyjehau/model';

import { LeafletMapContext } from '../../leaflet-map-context';
import { ClientOnly } from '../../utils/client-only';
import { OverlayGrid } from '../../modules/places-map/overlay-grid';
import { NewPlaceButton } from '../../modules/place-creation/new-place-button';
import { CategoryBar } from '../../components/category-bar/category-bar';
import { ZoomControls } from '../../components/zoom-controls/zoom-controls';
import { BackgroundGradient } from '../../modules/places-map/background-gradient';
import { useAppNavigation } from '../../routing/use-app-navigation';
import { useMapQueryParams } from '../../routing/use-map-query-params';
import { PlaceDetails } from '../../components/place-details/place-details';
import { SubcategoryButton } from '../../components/subcategory-button/subcategory-button';
import { SubcategoriesBar } from '../../components/subcategories-bar/subcategories-bar';
import { usePlacesFetcher } from '../../frontend-api/use-places-fetcher';
import { MapTypeSwitcher } from '../../components/map-type-switcher/map-type-switcher';
import { MapType } from '../../map-type';
import { useGetCoordsFromPlace } from '../../frontend-api/use-get-coords-from-place';
import { AutoGeolocateButton } from '../../components/auto-geolocate-button/auto-geolocate-button';
import { PlacesLoader } from '../../components/places-loader/places-loader';

type SSProps = {
	city: string;
};

export const getStaticProps: GetStaticProps<SSProps> = async (context) => {
	return {
		props: {
			city: (context.params?.city as string) ?? 'Kraków',
		},
	};
};

const LazyMap = lazy(() => import('../../modules/places-map/places-map'));
const LazyUserMarker = lazy(
	() => import('../../modules/places-map/user-position-marker'),
);

const LazyPlacesMarkers = lazy(
	() => import('../../modules/places-map/places-markers-list'),
);

export default function MapPage({ city }: SSProps) {
	const { coords } = useGetCoordsFromPlace(city ?? null);
	const { activePlaceId, activeCategory, activeSubcategory } =
		useMapQueryParams();

	const { goToNewPlaceCreator, closeActivePlace, setSubcategory } =
		useAppNavigation();

	const mapTypeStore = useRef(new MapType());

	const { setType, activeMapType } = mapTypeStore.current.use();

	const { data: places, getPlaceById } = usePlacesFetcher({
		category: activeCategory,
		subcategory: activeSubcategory,
	});

	const placesNotLoaded = places === undefined;

	const isOnMainMapPage = !activePlaceId;

	const activePlace = useMemo(() => {
		return getPlaceById(activePlaceId);
	}, [activePlaceId, getPlaceById]);

	const activeCategoryModel = useMemo(() => {
		return Categories[activeCategory as keyof typeof Categories] ?? null;
	}, [activeCategory]);

	const subcategories = useMemo(() => {
		return activeCategoryModel?.subcategories;
	}, [activeCategoryModel]);

	return (
		<LeafletMapContext>
			{placesNotLoaded && <PlacesLoader />}
			{coords && city && (
				<ClientOnly>
					<Suspense fallback="Map">
						<LazyMap defaultCoords={coords} mapType={activeMapType}>
							<Suspense fallback={null}>
								<LazyUserMarker />
								<LazyPlacesMarkers
									places={places ?? []}
									activeMarkerId={activePlaceId}
								/>
							</Suspense>
						</LazyMap>
					</Suspense>
				</ClientOnly>
			)}
			<Head>
				<title>Wyjehau - Miejsca psyjazne psom</title>
			</Head>
			<OverlayGrid
				subCategories={
					subcategories ? (
						<SubcategoriesBar>
							{subcategories.map((subCat) => (
								<SubcategoryButton
									active={activeSubcategory === subCat.type}
									key={subCat.type}
									onClick={() => {
										if (activeSubcategory === subCat.type) {
											setSubcategory(null);
										} else {
											setSubcategory(subCat.type);
										}
									}}
									accentColor={activeCategoryModel.color}
								>
									{subCat.label}
								</SubcategoryButton>
							))}
						</SubcategoriesBar>
					) : null
				}
				newPlaceButton={<NewPlaceButton onClick={goToNewPlaceCreator} />}
				isPlaceDetailsVisible={!isOnMainMapPage}
				categoryBar={<CategoryBar initialCity={city} />}
				placeDetails={
					activePlace ? (
						<PlaceDetails
							place={activePlace}
							onEscapeKeypress={closeActivePlace}
						/>
					) : null
				}
				zoomControls={<ZoomControls />}
				locateButton={<AutoGeolocateButton />}
				mapTypeSwitcher={
					<MapTypeSwitcher active={activeMapType} onChange={setType} />
				}
			/>
			<BackgroundGradient mapType={activeMapType} />
		</LeafletMapContext>
	);
}
