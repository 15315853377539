import { categoriesList, CategoryType } from '@wyjehau/model';
import styled from 'styled-components';
import { ComponentPropsWithoutRef } from 'react';

import { CategoryButton } from '../category-button/category-button';

const ListWrapper = styled.div({
	display: 'grid',
	gridGap: 4,
	gridAutoColumns: 'min-content',
	gridAutoFlow: 'column',
	alignItems: 'center',
});

type Props = ComponentPropsWithoutRef<'div'> & {
	active: CategoryType;
	onCategoryClick(category: CategoryType): void;
};

export const DesktopCategoriesList = ({
	active,
	onCategoryClick,
	...props
}: Props) => {
	return (
		<ListWrapper {...props}>
			{categoriesList.map((category) => (
				<CategoryButton
					active={category.type === active}
					category={category}
					key={category.type}
					onClick={onCategoryClick.bind(null, category.type)}
				/>
			))}
		</ListWrapper>
	);
};
