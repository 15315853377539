import useSWR from 'swr';

import { PositionStack } from '../services/positionstack';

const fetcher = (url: string) => window.fetch(url).then((r) => r.json());

export const useGetPlaceFromCoords = (
	latitude?: number,
	longitude?: number,
) => {
	const { data, error } = useSWR<PositionStack.PlaceDetailsDto>(
		latitude && longitude
			? `/api/geolocate/coords?q=${latitude},${longitude}`
			: null,
		fetcher,
	);

	return {
		place: data,
		error,
	};
};
