import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { CategoryType } from '@wyjehau/model';

import { PlaceCategoryDisplay } from '../place-category-display/place-category-display';
import { Sanity } from '../../services/sanity';
import { CloseButton } from '../close-button/close-button';
import { PlaceUnderVerification } from '../place-under-verification/place-under-verification';
import { GoogleMapsButton } from '../google-maps-button/google-maps-button';
import { useAppNavigation } from '../../routing/use-app-navigation';
import { usePlaceShare } from '../../use-pwa-share';
import { PlaceAddressFormatter } from '../../domain/place-address';

const Headline = styled('h1')({
	fontWeight: 500,
	fontSize: 20,
});

const Address = styled('span')({
	color: ThemeColors.gray3,
	marginBottom: 10,
	display: 'block',
});

const Wrapper = styled.div`
	padding: 16px;
	background: ${ThemeColors.white};
	border-radius: 24px;
	backdrop-filter: blur(10px);
	border: 1px solid #dbd9de;
	height: 100%;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const GoogleMapsLink = styled(GoogleMapsButton)`
	margin-top: 50px;
`;

// todo temp
const ShareButton = styled.button({
	marginTop: 30,
	width: '100%',
});

const addressFormatter = new PlaceAddressFormatter();

export const PlaceDetailsMobile = ({
	place,
	googleLink,
}: {
	place: Sanity.PlaceDto;
	googleLink: string;
}) => {
	const { closeActivePlace } = useAppNavigation();
	const { sharePlace, canShare } = usePlaceShare();

	return (
		<Wrapper>
			{place.published === false && <PlaceUnderVerification />}
			<Header>
				<Headline>{place.title}</Headline>
				<CloseButton onClick={closeActivePlace} />
			</Header>
			<Address>{addressFormatter.prettyPrintAddress(place.address)}</Address>
			<PlaceCategoryDisplay
				category={place.category as CategoryType}
				subcategories={place.subcategories?.items ?? []}
			/>
			<GoogleMapsLink href={googleLink} />
			{canShare && (
				<ShareButton
					onClick={() => {
						sharePlace(place._id, place.title);
					}}
				>
					Udostępnij
				</ShareButton>
			)}
		</Wrapper>
	);
};
