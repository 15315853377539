import styled from 'styled-components';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { ThemeColors } from '@wyjehau/theme';

import { ReactComponent as LocationPin } from '../../icons/assets/location-pin.svg';

const Root = styled.div({
	background: ThemeColors.gray1,
	display: 'flex',
	alignItems: 'center',
});

const StyledIcon = styled(LocationPin)({
	marginRight: 10,
	flex: '0 0 16px',
});

const Input = styled.input({
	background: 'transparent',
	border: 'none',
	width: '100%',
	appearance: 'none',
	height: 48,
	fontSize: 16,
	':focus': {
		border: 'none',
		outline: 'none',
	},
	'&::-webkit-search-cancel-button': {
		display: 'none',
	},
});

// eslint-disable-next-line react/display-name
export const UserLocationInput = forwardRef<
	HTMLInputElement,
	ComponentPropsWithoutRef<'input'>
>(({ className, ...props }, ref) => {
	return (
		<Root className={className}>
			<StyledIcon height={18} width={16} />
			<Input ref={ref} {...props} />
		</Root>
	);
});
