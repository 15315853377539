import useSWR from 'swr/immutable';
import { CategoryType } from '@wyjehau/model';
import { useCallback } from 'react';

import { Sanity } from '../services/sanity';

import { frontendApi } from './frontend-api';

type Options = {
	category?: CategoryType;
	subcategory?: string;
};

export const usePlacesFetcher = ({ category, subcategory }: Options) => {
	const { data, error } = useSWR<Sanity.PlaceDto[]>(
		[category, subcategory],
		frontendApi.fetchPlaces,
	);

	const getPlaceById = useCallback(
		(id: string) => {
			return data?.find((place) => place._id === id);
		},
		[data],
	);

	return {
		data,
		error,
		getPlaceById,
	};
};
