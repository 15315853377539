import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { CategoryType } from '@wyjehau/model';

export const useMapQueryParams = () => {
	const { query } = useRouter();

	return useMemo(() => {
		return {
			activePlaceId: (query.id as string) ?? null,
			activeCategory: (query.category as CategoryType) ?? null,
			activeSubcategory: (query.subcategory as string) ?? null,
		};
	}, [query]);
};
