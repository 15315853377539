import { IconType } from '@wyjehau/model';

import { BoneIcon } from './assets/bone-icon';
import { HomeIcon } from './assets/home-icon';
import { BuildingIcon } from './assets/building-icon';
import { PawIcon } from './assets/paw-icon';
import { TreeIcon } from './assets/tree-icon';

export const Icon: Record<IconType, typeof BoneIcon> = {
	Bone: BoneIcon,
	Home: HomeIcon,
	Building: BuildingIcon,
	Paw: PawIcon,
	Tree: TreeIcon,
};

export const IconPaths: Record<IconType, string> = {
	Bone: '/bone.svg',
	Building: '/building.svg',
	Paw: '/paw.svg',
	Tree: '/tree.svg',
	Home: '/home.svg',
};
