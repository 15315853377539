import * as React from 'react';
import { SVGAttributes } from 'react';

export const ArrowDown = (props: SVGAttributes<SVGElement>) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m2 6 6 6 6-6"
			stroke="#2C2D2F"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
	</svg>
);
