import * as React from 'react';
import { SVGProps } from 'react';

export const BuildingIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 14h12M6 5.333h.667M6 8h.667M6 10.666h.667M9.333 5.333H10M9.333 8H10M9.333 10.666H10M3.333 14V3.333A1.333 1.333 0 0 1 4.666 2h6.667a1.333 1.333 0 0 1 1.333 1.333V14"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
