import { useEffect, useMemo } from 'react';
import { GoogleCoordsLinkCreator } from '@wyjehau/model';

import { Sanity } from '../../services/sanity';
import { useIsMobile } from '../../utils/use-is-mobile';

import { PlaceDetailsMobile } from './place-details-mobile';
import { PlaceDetailsDesktop } from './place-details-desktop';

type Props = {
	place: Sanity.PlaceDto;
	onEscapeKeypress?(): void;
};

export const PlaceDetails = ({ place, onEscapeKeypress }: Props) => {
	const isMobile = useIsMobile();

	const googleLink = useMemo(() => {
		const { googleMapsLink, location } = place;

		return googleMapsLink
			? googleMapsLink
			: new GoogleCoordsLinkCreator(location).getDirectionsToPlace();
	}, [place]);

	useEffect(() => {
		const keyHandler = (e: KeyboardEvent) => {
			if (e.code === 'Escape') {
				onEscapeKeypress && onEscapeKeypress();
			}
		};

		addEventListener('keydown', keyHandler);

		return () => {
			removeEventListener('keydown', keyHandler);
		};
	}, [onEscapeKeypress]);

	return isMobile ? (
		<PlaceDetailsMobile place={place} googleLink={googleLink} />
	) : (
		<PlaceDetailsDesktop place={place} googleLink={googleLink} />
	);
};
