import styled from 'styled-components';
import { ComponentPropsWithRef } from 'react';

const Wrapper = styled.div`
	display: grid;
	grid-gap: 6px;
	grid-auto-flow: column;
	grid-auto-columns: min-content;
	pointer-events: all;
	scroll-snap-type: x mandatory;
`;

export const SubcategoriesBar = (props: ComponentPropsWithRef<'div'>) => {
	return <Wrapper {...props} />;
};
