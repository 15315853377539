import styled from 'styled-components';
import { ComponentPropsWithRef } from 'react';

import { Logo } from '../../icons/assets/logo';
import { WyjehauText } from '../../icons/assets/wyjehau-text';

const WrapperDesktop = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const WrapperMobile = styled.div`
	display: flex;
	align-items: center;
`;

const logoDesktopStyles = {
	width: 58,
	marginBottom: 5,
};

const logoMobileStyles = {
	width: 44,
	marginRight: 12,
};

export const FullLogo = ({
	variant = 'desktop',
	...props
}: ComponentPropsWithRef<'div'> & {
	variant: 'desktop' | 'mobile';
}) => {
	const WrapperToRender =
		variant === 'desktop' ? WrapperDesktop : WrapperMobile;

	return (
		<WrapperToRender {...props}>
			<Logo
				style={variant === 'desktop' ? logoDesktopStyles : logoMobileStyles}
			/>
			<WyjehauText />
		</WrapperToRender>
	);
};
