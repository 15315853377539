import { ReactNode } from 'react';

import { useIsMobile } from '../../utils/use-is-mobile';
import { DesktopMapPageGrid } from '../../components/desktop-map-page-grid/desktop-map-page-grid';
import { MobileMapPageGrid } from '../../components/mobile-map-page-grid/mobile-map-page-grid';

type Props = {
	categoryBar: ReactNode;
	placeDetails: ReactNode;
	isPlaceDetailsVisible: boolean;
	newPlaceButton: ReactNode;
	zoomControls: ReactNode;
	locateButton: ReactNode;
	subCategories: ReactNode;
	mapTypeSwitcher: ReactNode;
};

export const OverlayGrid = ({
	categoryBar,
	placeDetails,
	isPlaceDetailsVisible,
	newPlaceButton,
	zoomControls,
	locateButton,
	subCategories,
	mapTypeSwitcher,
}: Props) => {
	const isMobile = useIsMobile();

	if (isMobile) {
		return (
			<MobileMapPageGrid
				newPlaceButton={newPlaceButton}
				locateButton={locateButton}
				placeDetails={placeDetails}
				subCategories={subCategories}
				zoomControls={zoomControls}
				categoryBar={categoryBar}
				mapTypeSwitcher={mapTypeSwitcher}
				isPlaceDetailsVisible={isPlaceDetailsVisible}
			/>
		);
	} else {
		return (
			<DesktopMapPageGrid
				newPlaceButton={newPlaceButton}
				locateButton={locateButton}
				placeDetails={placeDetails}
				subCategories={subCategories}
				zoomControls={zoomControls}
				categoryBar={categoryBar}
				mapTypeSwitcher={mapTypeSwitcher}
			/>
		);
	}
};
