import { ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';
import { Category } from '@wyjehau/model';
import { ThemeColors } from '@wyjehau/theme';

import { Icon as CategoryIcon } from '../../icons/icons';

type ExternalProps = {
	active?: boolean;
};

type StyleProps = {
	accentColor: string;
} & ExternalProps;

type BaseProps = ComponentPropsWithoutRef<'button'> & ExternalProps;

const ButtonRoot = styled.button<StyleProps>(
	{
		whiteSpace: 'nowrap',
		height: 38,
		padding: '0 10px',
		borderRadius: 5,
		display: 'flex',
		alignItems: 'center',
		transition: '0.2s',
		fontSize: 16,
		':hover': {
			background: ThemeColors.gray1,
		},
		'& > svg': {
			marginRight: 8,
		},
	},
	({ active, accentColor }) =>
		active &&
		css({
			background: accentColor,
			color: ThemeColors.white,
			':hover': {
				background: accentColor,
				color: ThemeColors.white,
			},
		}),
);

export const CategoryButton = ({
	category,
	...props
}: BaseProps & {
	category: Category;
}) => {
	const Icon = CategoryIcon[category.icon];

	return (
		<ButtonRoot type="button" accentColor={category.color} {...props}>
			<Icon width={16} height={16} />
			{category.label}
		</ButtonRoot>
	);
};
