import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import { ThemeColors } from '@wyjehau/theme';

import { PlusInCircle } from '../../icons/assets/plus-in-circle';

const Button = styled.button`
	background: ${ThemeColors.blue3};
	color: white;
	display: inline-flex;
	align-items: center;
	padding: 0 27px;
	border-radius: 10px;
	justify-context: center;
	width: 100%;
	justify-content: center;
`;

const Label = styled.span`
	font-size: 16px;
	font-weight: 500;
	line-height: 48px;
`;

const Icon = styled(PlusInCircle)`
	width: 18px;
	height: 18px;
	margin-right: 7px;
`;

export const NewPlaceButton = (props: HTMLAttributes<HTMLButtonElement>) => {
	return (
		<Button {...props}>
			<Icon />
			<Label>Dodaj miejsce</Label>
		</Button>
	);
};
