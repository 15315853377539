import { FormEventHandler, useEffect, useState } from 'react';
import { usePosition } from 'use-position';
import { CategoryType } from '@wyjehau/model';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { LatLngLiteral } from 'leaflet';

import { useLeafletMap } from '../../leaflet-map-context';
import { useAppNavigation } from '../../routing/use-app-navigation';
import { frontendApi } from '../../frontend-api/frontend-api';
import { useGetPlaceFromCoords } from '../../frontend-api/use-get-place-from-coords';
import { useIsMobile } from '../../utils/use-is-mobile';
import { DesktopCategoriesList } from '../desktop-categories-list/desktop-categories-list';
import { useMapQueryParams } from '../../routing/use-map-query-params';
import { MobileCategoriesSelect } from '../mobile-categories-select/mobile-categories-select';
import { UserLocationInput } from '../user-location-input/user-location-input';

import { CategoryBarStyles } from './category-bar.styles';

const {
	LeftColumn,
	Root,
	InputFormWrapper,
	MobileRoot,
	MobileBottomColumn,
	MobileTopColumn,
} = CategoryBarStyles;

type SharedInternalProps = {
	locationInputValue: string;
	activeCategory: CategoryType;
	onFormSubmit: FormEventHandler<HTMLFormElement>;
	onInputValueChange(value: string): void;
	onCategorySelected(category: CategoryType): void;
};

const DesktopCategoryBar = ({
	locationInputValue,
	onInputValueChange,
	onCategorySelected,
	activeCategory,
	onFormSubmit,
}: SharedInternalProps) => {
	return (
		<Root>
			<LeftColumn>
				<InputFormWrapper onSubmit={onFormSubmit}>
					<UserLocationInput
						type="search"
						placeholder="Gdzie jesteście?"
						value={locationInputValue}
						onChange={(e) => onInputValueChange(e.currentTarget.value)}
					/>
				</InputFormWrapper>
			</LeftColumn>
			<DesktopCategoriesList
				onCategoryClick={onCategorySelected}
				active={activeCategory}
			/>
		</Root>
	);
};

const MobileCategoryBar = ({
	locationInputValue,
	onInputValueChange,
	onCategorySelected,
	activeCategory,
	onFormSubmit,
}: SharedInternalProps) => {
	return (
		<MobileRoot>
			<MobileTopColumn>
				<InputFormWrapper onSubmit={onFormSubmit}>
					<UserLocationInput
						type="search"
						placeholder="Gdzie jesteście?"
						value={locationInputValue}
						onChange={(e) => onInputValueChange(e.currentTarget.value)}
					/>
				</InputFormWrapper>
			</MobileTopColumn>
			<MobileBottomColumn>
				<MobileCategoriesSelect
					activeCategory={activeCategory}
					onChange={onCategorySelected}
				/>
			</MobileBottomColumn>
		</MobileRoot>
	);
};

export const CategoryBar = ({ initialCity }: { initialCity?: string }) => {
	const isMobile = useIsMobile();
	const [coordsToFetch, setCoordsToFetch] = useState<null | LatLngLiteral>(
		null,
	);
	const userPosition = usePosition(false);
	const { setCategory } = useAppNavigation();
	const { activeCategory } = useMapQueryParams();
	const [field, setField] = useState(initialCity ?? '');
	const map = useLeafletMap();
	const mapMoveEndHandler = useDebouncedCallback(() => {
		if (map) {
			setCoordsToFetch(map.getCenter());
		}
	}, 300);

	useEffect(() => {
		const { latitude, longitude } = userPosition;

		if (latitude && longitude && coordsToFetch === null) {
			setCoordsToFetch({
				lng: userPosition.longitude as number,
				lat: userPosition.latitude as number,
			});
		}
	}, [userPosition, setCoordsToFetch, coordsToFetch]);

	useEffect(() => {
		map?.on('moveend', mapMoveEndHandler);

		return () => {
			map?.off('moveend', mapMoveEndHandler);
		};
	}, [map, mapMoveEndHandler]);

	const { place } = useGetPlaceFromCoords(
		coordsToFetch?.lat,
		coordsToFetch?.lng,
	);

	useEffect(() => {
		if (place) {
			setField(place.administrative_area ?? place.locality ?? place.county);
		}
	}, [place]);

	const onSubmit: FormEventHandler = (e) => {
		e.preventDefault();

		frontendApi.getCoordsFromPlace(field).then((resp) => {
			if (resp.lat && resp.lng) {
				map?.flyTo({
					lng: resp.lng,
					lat: resp.lat,
				});
			}
		});
	};

	const handleCategorySelected = (cat: CategoryType | null | 'all') => {
		if (activeCategory === cat || cat === null || cat === 'all') {
			return setCategory(null);
		}

		return setCategory(cat);
	};

	switch (isMobile) {
		case true: {
			return (
				<MobileCategoryBar
					locationInputValue={field}
					onInputValueChange={setField}
					onCategorySelected={handleCategorySelected}
					activeCategory={activeCategory}
					onFormSubmit={onSubmit}
				/>
			);
		}
		case false: {
			return (
				<DesktopCategoryBar
					locationInputValue={field}
					onInputValueChange={setField}
					onCategorySelected={handleCategorySelected}
					activeCategory={activeCategory}
					onFormSubmit={onSubmit}
				/>
			);
		}
	}
};
