export class PlaceAddressFormatter {
	private incompleteAddressRegex = new RegExp(/^(, )(.+)/);

	prettyPrintAddress(address: string) {
		const regexpResult = this.incompleteAddressRegex.exec(address);

		if (!regexpResult) {
			return address;
		}

		return regexpResult[2] ? regexpResult[2] : address;
	}
}
