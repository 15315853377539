import { createDomain, forward } from 'effector';
import { useStore } from 'effector-react';

export class MapType {
	static Map = 'map' as const;
	static Satellite = 'satellite' as const;

	static toggleType(currentType: MapTypes) {
		return currentType === MapType.Map ? MapType.Satellite : MapType.Map;
	}

	private domain = createDomain('MapType');

	setType = this.domain.createEvent<MapTypes>();

	activeType = this.domain.createStore<MapTypes>(MapType.Map);

	constructor() {
		forward({
			from: this.setType,
			to: this.activeType,
		});
	}

	use() {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const activeMapType = useStore(this.activeType);

		return {
			activeMapType: activeMapType,
			setType: this.setType,
		};
	}
}

export type MapTypes = typeof MapType.Map | typeof MapType.Satellite;
