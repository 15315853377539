import { useCallback } from 'react';

export const usePwaShare = () => {
	const canShare = 'share' in navigator;

	const share = useCallback((data: ShareData) => {
		if ('share' in navigator) {
			navigator.share(data);
		}
	}, []);

	return {
		canShare,
		share,
	};
};

export const usePlaceShare = () => {
	const { canShare, share } = usePwaShare();

	const sharePlace = useCallback(
		(placeId: string, placeName: string) => {
			const placeUrl = `/map/place/${placeId}/${encodeURIComponent(
				placeName.toLocaleLowerCase(),
			)}`;

			return share({
				url: `${window.location.origin}${placeUrl}`,
				title: placeName,
				text: 'Zobacz to miejsce na wyjeahu.pl',
			});
		},
		[share],
	);

	return {
		canShare,
		sharePlace,
	};
};
